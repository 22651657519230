import {EMPTY} from './guid';
import {RelationBroker} from '../models/relation-broker.model';

export class CustomerOrder {
  portalId: string | null = null; //string = EMPTY;
  cartIds: string[] = [];
  docId: string | null = null;
  doc: RelationBroker | null = null;

  countryId: string | null = null;
}
