import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {AuthenticationClientService} from './authentication-client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientAuthGuard  {
  constructor(private authentication: AuthenticationClientService, private router: Router) {
  }

  checkLogin(): boolean {
    if (this.authentication.isLoggedIn.value) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(): boolean {
    return this.checkLogin();
  }

  canActivate(): boolean {
    if (window.self !== window.top && localStorage.getItem('Adminjwt')) {
      return true;
    }else{
       return this.checkLogin();

    }
  }
}
